<template>
  <div id="child-login">
    <TopNavigation :style="hideBackButtonOnConfirmationModal" arrowAction="/" helpAction="/help" />
    <div style="height: 60px"></div>
    <div class="guardian-login-form">
      <!-- <div class="guardian-login-form__title">
        <img
          id="child-login__logo"
          class="mx-auto"
          src="../../guardian/assets/images/logo-color.png"
          alt="Logo"
        />
        <p style="font-family: var(--sans); margin: 15px 0 10px 0">
          Good afternoon!
        </p>
      </div> -->
      <!-- guardian-login-form__content -->
      <form
        class=""
        @submit.prevent="sendLogin(true)"
      >
      <div class="guardian-login-form__input-block">
        <label for="" class="guardian-login-form__label">Enter your Guardian’s Email address!</label>
        <input
          v-model="guardianEmail"
          class="guardian-login-form__input"
          placeholder="Enter your Guardians Email"
          type="email"
          spellcheck="false"
        />
        </div>
        <div class="guardian-login-form__input-block text-align">
          <!-- <label for="" style="text-align: center; margin-top: 12px"
          >Pin</label
        > -->
        <p class="guardian-login-form__pit-label">Pin</p>
        <AppPinInput
          style="
            margin-bottom: 30px;
            width: 100%;
            display: flex;
            justify-content: center;
          "
          @enter="getPin"
        />
        </div>
        

        <button
          :disabled="validateGuardianEmail && validatePincode"
          type="submit"
          class="guardian-login-form__button shadow"
          style="margin-top: 15px"
        >
          Login
        </button>
        <RouterLink
          replace
          to="/recover"
          class="guardian-login-form__forgot"
          style="text-decoration: none; font-family: var(--fredoka)"
        >
          Forgot Password?
        </RouterLink>
      </form>
    </div>
    <div v-if="waitingConfirmation" class="waitingConfirmation">
      <img
        alt="Logo"
        id="child-login__logo"
        src="../../guardian/assets/images/logo-color.png"
      />
      <div class="guardian-login-form__title" style="margin-bottom: auto">
        <img
          alt="Avatar"
          id="child-login__avatar"
          class="mx-auto"
          src="../../login/assets/images/login_avatar.png"
        />
        <p
          style="
            font-family: var(--sans);
            font-size: 0.75rem;
            margin: 15px 30px 70px 30px;
          "
        >
          Hey, we’re waiting on your <strong>Guardian</strong> to approve your
          login, sit tight!
        </p>
        <Spinner style="margin-bottom: 75px" />
        <button class="guardian-login-form__button" @click="cancelWaiting">
          Cancel
        </button>
      </div>
    </div>
    <LoginFooter style="margin-top: 40px" class="footer"/>
  </div>
</template>

<script>
  import axios from "axios";
  import config from "../../children/config";
  import LoginFooter from "../../login/components/LoginFooter";
  import Spinner from "../../login/components/Spinner";
  import AppPinInput from "../../children/components/AppPinInput";
  import TopNavigation from "./TopNavigation.vue";
  import { mapActions } from "vuex";

  export default {
    components: {
      LoginFooter,
      AppPinInput,
      Spinner,
      TopNavigation,
    },
    data() {
      return {
        childPin: "",
        guardianEmail: "",
        error: "",
        waitingConfirmation: false,
        isLoading: false,
        isBtnLoading: false,
      };
    },
    computed: {
      validatePincode() {
        return this.childPin.length !== 4;
      },
      validateGuardianEmail() {
        return this.guardianEmail.length === 0;
      },
      hideBackButtonOnConfirmationModal() {
        return this.waitingConfirmation
          ? "opacity: 0; pointer-events: none;"
          : "opacity: 1; pointer-events: all;";
      },
    },
    watch: {
      waitingConfirmation: function () {
        if (this.waitingConfirmation) {
          setInterval(() => {
            this.sendLogin(false);
          }, 5000);
        }
      },
    },
    beforeMount() {
      this.isLoading = true;
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);

      if (params.get("app") && params.get("app") === "true") {
        localStorage.setItem("app", true);
      }

      if (params.get("logout") && params.get("logout") === "true") {
        window.localStorage.removeItem("children_token");
        window.localStorage.removeItem("user_type");
        window.localStorage.removeItem("children_family_id");
        window.localStorage.removeItem("children_user_id");
        localStorage.removeItem("password");
        localStorage.removeItem("family_id");
        localStorage.clear();
      }

      let sessionToken = localStorage.getItem("children_token");
      let sessionUser = localStorage.getItem("children_user_id");
      let sessionUserType = localStorage.getItem("user_type");
      let sessionFamilyId = localStorage.getItem("children_family_id");

      if (
        sessionToken != null &&
        sessionUser != null &&
        sessionUserType != null &&
        sessionFamilyId != null
      ) {
        if (sessionUserType === "child") {
          if (params.get("app") && params.get("app") === "true") {
            location.replace(
              config.CHILD_HOST +
                `/children?token=${sessionToken}&userId=${sessionUser}&family_id_check=${sessionFamilyId}&app=true`
            );
          } else {
            location.replace(
              `/children?token=${sessionToken}&userId=${sessionUser}&family_id_check=${sessionFamilyId}`
            );
          }
        } else if (sessionUserType === "guardian") {
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
    methods: {
      ...mapActions(["TOAST"]),
      getPin(pin) {
        this.childPin = pin;
      },

      cancelWaiting() {
        location.replace("/");
      },

      sendLogin(isNull) {
        this.isBtnLoading = true;
        const url =
          isNull === true
            ? config.API_HOST + "login/frontend?send_notification=1"
            : config.API_HOST + "login/frontend";

        if (this.childPin && this.guardianEmail.length > 0) {
          axios
            .post(url, {
              password: this.childPin,
              family_id: this.guardianEmail,
            })
            .then((response) => {
              if (response.data.statusCode === 200) {
                const { token, userId, family_id } = response.data.data;

                this.childLoginFamilyId = family_id;
                let selectedFamily = this.childLoginFamilyId;
                this.waitingConfirmation = false;

                localStorage.setItem("children_user_id", userId);
                localStorage.setItem("children_token", token);
                localStorage.setItem("children_family_id", selectedFamily);
                localStorage.setItem("user_type", "child");
                console.log(response);
                location.replace(
                  "https://" +
                    window.location.host +
                    `/children?token=${token}&userId=${userId}&family_id_check=${selectedFamily}`
                );
              } else if (
                response.data.statusCode === 203 ||
                response.data.statusCode === 404
              ) {
                this.TOAST(response.data.message);
                this.isBtnLoading = false;
              } else if (response.data.statusCode === 406) {
                this.isBtnLoading = false;
                this.childLoginFamilyId = response.data.data.id;

                localStorage.setItem("password", this.childPin);
                localStorage.setItem("family_id", response.data.data.id);

                this.waitingConfirmation = true;
              } else {
                this.TOAST(response.data.message);
                console.log(response);
                this.isBtnLoading = false;
              }
            })
            .catch((error) => {
              throw error;
            });
        }
        this.isBtnLoading = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main-top-nav {
    background: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    z-index: 100000;
    padding: 15px;
  }

  #child-login {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 15px;

    &__logo {
      height: 75px;
    }

    &__avatar {
      height: 100px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  .guardian-login-form {
    padding-top: 5em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
    margin-bottom: auto;

    &__content {
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      background: white;
      border: 1px solid #dedede;
      padding: 20px;
      width: 85%;

      label {
        font-size: 12px;
        color: #777777;
        margin-bottom: 7px;
        display: block;
      }
    }
    &__pit-label{
      text-align: center;
    }
    &__input {
    background-color: #EBEBEB;
    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 15px;
    border-radius: 30px;
    outline: none;
    font-size: 14px;
    color: #AAAAAA !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-color: #d8d8d8;
  }
  &__input-block {
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.8em;
  }

  &__label {
    font-family: var(--sans);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    
  }

    &__title {
      margin-bottom: 40px;
      text-align: center;
      color: #7a6159;
      width: 85%;

      h1 {
        font-size: 20px;
      }

      p {
        margin-top: 5px;
        font-size: 13px;
      }
    }

    &__button {
    width: 92%;
    min-width: 200px;
    margin: 0 auto;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--main);
    background: var(--main);
    color: white;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
    text-decoration: none;

    &:active {
      transform: scale(0.99);
    }

      span {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:focus {
        background-color: var(--main);
      }
    }

    &__forgot {
    text-align: center;
    font-size: 1rem;
    color: var(--main);
    display: block;
    margin-top: 20px;
  }

    &__register {
      margin-top: 25px;
      text-align: center;
      width: 85%;
      color: #777;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 5px;
      }
    }
  }
  .footer{
  position: absolute;
  bottom: 0;
  padding: 1.2em;
}

  ::placeholder {
    color: #bebebe;
  }

  .waitingConfirmation {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 80px 20px 20px 20px;

    h1 {
      text-align: center;
      color: var(--red);
      font-size: 16px;
      padding: 0 20px;
      line-height: 20px;
    }
  }
</style>
